<template>
  <div class="container form-container">
    <img src="../../public/assets/images/comprarealizada.png" alt="">
      <h3>¡SU PEDIDO SE HA COMPLETADO!</h3>
      <button type="button" class="btn btn-default" data-dismiss="modal">
        <router-link class="modal-router-link" :to="{ name: 'Store'}">
          Ir a tienda
        </router-link>
      </button>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";

// TODO FALTA: no permitir volver atras desde la pantalla orden finalizada.
export default {
  name: "Complete-Order",
  computed: {
    ...mapState('ecommerce', ["basket" ]),
    ...mapGetters({
      basketSum: "ecommerce/basketSum"
    }),
  },
  methods: {
    ...mapMutations({
      clearBasketMutation: 'ecommerce/CLEAR_BASKET',
      clearOrderMutation: 'ecommerce/CLEAR_ORDER'
    }),
    clearBasket() {
      this.clearBasketMutation();
    },
    clearOrder() {
      this.clearOrderMutation();
    },
    setFormValues(){
      this.createOrderEcommerce({
        values: this.formValues,
        items: this.basket,
        totals:{
            subtotal:this.basketSum
          }
      });
    },
    validateClientData() {
      if(this.formValues.client.name.split(' ').length < 2){
        console.log('nombre incomplettoooooooo')
        //TODO chequear que el nombre este completo
      }
    },
  },
  created() {
    this.clearBasket();
  },
  beforeDestroy() {
    this.clearOrder();
  }
};
</script>

<style scoped>

.form-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}
.form-container img {
  margin-top: 30px;
  height: 250px;
  width: auto;
  object-fit: cover;
}

.btn-default {
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
  width: 130px;
}

h3 {
  margin: 1em 0;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    outline-offset: none;
}

</style>
